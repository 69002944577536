<template lang="pug">
.laws
  cleanable-title(@reset="$emit('reset', 'lotLawIds')") ЗАКОНЫ
  .filter-card__checkboxes
    ui-checkbox(as-group v-model="selectAllGetter" id="selectAllLaws" label="Выбрать все" :indeterminate="isIndeterminate && !selectAllGetter")
    checkbox-group(v-model="modelValue.lotLawIds" :options="filters")
</template>

<script lang="ts">
import { computed, defineComponent, } from "vue";
import { useVModel } from "@vueuse/core";

import type { PropType } from "vue";
import type { BaseSearchFormInterface } from "@/stores/search/SearchFormInterface";
import CheckboxGroup from "@/components/ui/checkbox/CheckboxGroup.vue";
import CleanableTitle from "@/components/searchForm/elements/CleanableTitle.vue";
import UiCheckbox from "@/components/ui/checkbox/UiCheckbox.vue";
import useManualsStore, { lawsOptions } from "~/stores/manuals/useManualsStore";

export default defineComponent({
  name: "LawsFilter",
  components: {
    UiCheckbox,
    CheckboxGroup,
    CleanableTitle,
  },
  props: {
    modelValue: {
      type: Object as PropType<BaseSearchFormInterface>,
      default: () => ({}),
    },
  },
  emits: [
    'reset',
    'update:modelValue',
  ],
  setup(props, context) {

    const manualsStore = useManualsStore()
    const searchForm = useVModel(props, 'modelValue', context.emit);

    const filters = lawsOptions || []
    const allIds = filters.map(e => e.id)

    const selectAllGetter = computed({
      get: () => allIds.every(id => searchForm.value?.lotLawIds?.includes(id)),
      set(value: boolean) {
        searchForm.value.lotLawIds = value ? allIds : []
      }
    })

    const isIndeterminate = computed(() => allIds.some(id => searchForm.value?.lotLawIds.includes(id)))

    return {
      filters,
      isIndeterminate,
      selectAllGetter,
    };
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixin/searchForm";

.laws {
  display: flex;
  flex-flow: column;

  .filter-card__checkboxes {
    @include checkboxes_input(4);

    :deep(.checkbox-group) {
      display: contents;
    }
  }
}
</style>
