<template lang="pug">
.lot-type
  cleanable-title(@reset="$emit('reset', 'lotTypeShortIds')") ТИП ЗАКУПКИ
  .margin
    ui-checkbox(as-group v-model="mainFiltersGetter" label="Выбрать все" :indeterminate="isIndeterminate && !mainFiltersGetter" id="selectAllSTenderTypeShort")
    checkbox-group.filter-card__checkboxes(v-model="modelValue.lotTypeShortIds" :options="filters")
      el-dropdown(:hide-on-click="false" :teleported="false")
        ui-checkbox.other(as-group v-model="otherGetter" :indeterminate="isIndeterminateOthers && !otherGetter" label="Прочие" hint-type="info" hint-size="small" id="selectOthersTenderTypeShort")
        template(#dropdown)
          checkbox-group.others-checkboxes(v-model="modelValue.lotTypeShortIds" :options="otherFilters")
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useVModel } from "@vueuse/core";

import type { PropType } from "vue";
import type { BaseSearchFormInterface } from "@/stores/search/SearchFormInterface";
import CheckboxGroup from "@/components/ui/checkbox/CheckboxGroup.vue";
import CleanableTitle from "@/components/searchForm/elements/CleanableTitle.vue";
import UiCheckbox from "@/components/ui/checkbox/UiCheckbox.vue";
import UiIcon from "@/components/ui/icon/UiIcon.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";
import { typeLotAdditionalOptions, typeLotOptions } from "~/stores/manuals/useManualsStore";

export default defineComponent({
  name: "TypeLot",
  components: {
    UiIcon,
    UiCheckbox,
    CheckboxGroup,
    CleanableTitle,
  },
  props: {
    modelValue: {
      type: Object as PropType<BaseSearchFormInterface>,
      default: () => ({}),
    },
  },
  emits: [
    'reset',
    'update:modelValue',
  ],
  setup(props, context) {

    const searchForm = useVModel(props, 'modelValue', context.emit);

    const filters = typeLotOptions
    const otherFilters = typeLotAdditionalOptions

    const mainFiltersIds = filters.map(e => e.id)
    const additionalFiltersIds = otherFilters.map(e => e.id);

    const allIds = [...mainFiltersIds, ...additionalFiltersIds]

    function isLotTypeShortIdsIncludesId(id) {
      return searchForm.value?.lotTypeShortIds.includes(id);
    }

    const otherGetter = computed({
      get: () => additionalFiltersIds.every(isLotTypeShortIdsIncludesId),
      set(value: boolean) {
        searchForm.value.lotTypeShortIds =
          value
            ? [...new Set([...searchForm.value.lotTypeShortIds, ...additionalFiltersIds])]
            : searchForm.value.lotTypeShortIds.filter(id => !additionalFiltersIds.includes(id))
      }
    })

    const mainFiltersGetter = computed({
      get: () => allIds.every(isLotTypeShortIdsIncludesId),
      set(value: boolean) {
        searchForm.value.lotTypeShortIds = value ? allIds : []
      }
    })

    const isIndeterminate = computed(() => allIds.some(isLotTypeShortIdsIncludesId))
    const isIndeterminateOthers = computed(() => additionalFiltersIds.some(isLotTypeShortIdsIncludesId))

    return {
      filters,
      otherFilters,
      otherGetter,
      mainFiltersGetter,
      isIndeterminate,
      isIndeterminateOthers,
      UiIconNames,
    };
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixin/searchForm";

.lot-type {
  display: flex;
  flex-flow: column;
  max-width: var(--max-advanced-filters-width);

  .margin {
    margin-top: 8px;
  }

  .other {
    :deep(label) {
      display: flex;
      align-items: center;
    }
  }

  .filter-card__checkboxes {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
  }

  .others-checkboxes {
    display: grid;
    grid-template-columns: 200px 320px;
    grid-template-rows: repeat(6, auto);
    grid-auto-flow: column;
    column-gap: 16px;
    padding: 8px 16px;
  }

  .hint-icon {
    color: var(--main-red-color);
  }
}
</style>
